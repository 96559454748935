body.navigation-open{
  overflow: hidden;
}
.top-navigation {
  background-color: $navigation-background;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $navigation-height-xs;
  line-height: $navigation-height-xs;
  z-index: 1000;
  padding: 0 20px;
  @media (min-width: $content-width-navigation){
    height: $navigation-height;
    line-height: $navigation-height;
  }
  .top-navigation--logo{
    position: relative;
    float: left;
    z-index: 1002;
    a {
      display: inline-block;
      img{
        height: 30px;
        @media(min-width: $screen-size-md+1){
          height: 40px;
        }
      }
    }
  }
  .navigation--toggle{
    float: right;
    line-height: $navigation-height-xs;
    position: relative;
    z-index: 1002;
    height: $navigation-height-xs;
    a{
      display: inline-block;
      width: 25px;
      .navigation--toggle--bar{
        position: absolute;
        left: 0;
        -webkit-transition: transform .3s ease-in-out;
        -moz-transition: transform .3s ease-in-out;
        -ms-transition: transform .3s ease-in-out;
        -o-transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        .navigation--toggle--bar--line{
          height: 1px;
          width: 25px;
          background-color: #fff;
          margin: 10px 0;
          -webkit-transition: transform .3s ease-in-out;
          -moz-transition: transform .3s ease-in-out;
          -ms-transition: transform .3s ease-in-out;
          -o-transition: transform .3s ease-in-out;
          transition: transform .3s ease-in-out;
        }
        &.navigation--toggle--bar-top{
          top: 10px;

        }
        &.navigation--toggle--bar-bottom{
          top: 20px;
        }
      }
    }
    @media (min-width: $content-width-navigation){
      display: none;
    }
  }
  .navigation {
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100vh;
    -webkit-transition: all 0.5s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    -moz-transition: all 0.5s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    -ms-transition: all 0.5s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    -o-transition: all 0.5s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    transition: all 0.5s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    z-index: 1001;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
    padding: 0 48px;
    overflow-y: scroll;
    @media (min-width: $content-width-navigation) {
      float: right;
      visibility: visible;
      position: static;
      top: auto;
      left: auto;
      width: auto;
      height: auto;
      background-color: transparent;
      opacity: 1;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none;
      padding-left: 0;
      overflow-y: visible;
    }
    .language-navigation,
    .main-navigation {
      @media (min-width: $content-width-navigation) {
        display: inline-block;
      }
      ul {
        margin-left: -15px;
        margin-right: -15px;
        li {
          position: static;
          -webkit-transition: opacity .2s ease-in .3s;
          -moz-transition: opacity .2s ease-in .3s;
          -ms-transition: opacity .2s ease-in .3s;
          -o-transition: opacity .2s ease-in .3s;
          transition: opacity .2s ease-in .3s;
          opacity: 0;

          @media (min-width: $content-width-navigation) {
            position: relative;
            display: inline-block;
            opacity: 1;
          }
          a {
            display: block;
            padding: 0;
            @media (min-width: $content-width-navigation) {
              &:hover {
                color: $link-color;
              }
              display: inline-block;
              padding: 0 15px;
            }
          }
          &.current_page_ancestor,
          &.current_page_item{
            > a{
              color: $link-color;
            }
            &.page_item_has_children,
            &.menu-item-has-children {
              .children {
                display: block;
                @media (min-width: $content-width-navigation) {
                  display: none;
                }
              }
            }
          }
          &.page_item_has_children,
          &.menu-item-has-children {
            .children{
              margin-left: 30px;
              display: none;
            }
            @media (min-width: $content-width-navigation) {
              .children,
              .sub-menu {
                margin-left: 0;
                position: absolute;
                top: 100%;
                left: 0;
                width: auto;
                background: $navigation-background;
                padding: 0 15px;
                li {
                  display: block;
                  line-height: normal;
                  white-space: nowrap;
                  a {
                    display: block;
                    padding: 15px 0;
                  }
                }
              }
              &:hover {
                .children,
                .sub-menu {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    nav.main-navigation {
      @media (min-width: $content-width-navigation) {
        float: right;
      }
      .menu {
        > ul {
          > li {
            > a {
              border-top: 1px solid $navigation-seperator-color;
              @media (min-width: $content-width-navigation) {
                border: 0;
              }
            }
            @media (min-width: $content-width-navigation) {
              > ul.children {
                margin-top: -1px;
                li {
                  a {
                    border-top: 1px solid $navigation-seperator-color;
                  }
                }
              }
            }
            &:first-child{
              > a {
                border: 0;
              }
            }
          }
        }
      }
    }
    .language-navigation {
      .wpml-ls-legacy-list-horizontal {
        border: 0;
        padding: 0;
        ul {
          border-top: 1px solid $navigation-seperator-color;
          -webkit-transition: opacity .2s ease-in .3s;
          -moz-transition: opacity .2s ease-in .3s;
          -ms-transition: opacity .2s ease-in .3s;
          -o-transition: opacity .2s ease-in .3s;
          transition: opacity .2s ease-in .3s;
          opacity: 0;
          @media (min-width: $screen-size-sm) {
            opacity: 1;
            border: 0;
          }
          li {
            padding: 0;
            a {
              padding: 0;
              span{
                vertical-align: baseline;
              }
              &:after {
                content: "| ";
                padding: 0 15px;
                color: $navigation-seperator-color;
                @media (min-width: $screen-size-sm) {
                  padding: 0 5px;
                }
              }
            }
            &.wpml-ls-current-language {
              a {
                color: $link-color;
              }
            }
            @media (min-width: $screen-size-sm) {
              &:first-child {
                a {
                  padding-left: 15px;
                }
              }
            }
            &:last-child {
              a:after {
                content: "";
              }
            }
          }
        }
      }
    }
    @media (min-width: $content-width-navigation){
      height: $navigation-height;
      line-height: $navigation-height;
      padding: 0;
      .top-navigation--logo{
        a {
          img {
            height: $navigation-height - 35px;
          }
        }
      }
    }
  }
  &.open{
    .navigation{
      visibility: visible;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
      transition-delay: 0s, 0s, 0.3s;
      opacity: 1;
      ul{
        li{
          opacity: 1;
        }
      }
      .language-navigation{
        .wpml-ls-legacy-list-horizontal {
          ul{
            opacity: 1;
          }
        }
      }
    }
    .navigation--toggle{
      a{
        .navigation--toggle--bar{
          &.navigation--toggle--bar-top{
            -webkit-transform: translateY(5px);
            -moz-transform: translateY(5px);
            -ms-transform: translateY(5px);
            -o-transform: translateY(5px);
            transform: translateY(5px);
            .navigation--toggle--bar--line{
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
          &.navigation--toggle--bar-bottom{
            -webkit-transform: translateY(-5px);
            -moz-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            -o-transform: translateY(-5px);
            transform: translateY(-5px);
            .navigation--toggle--bar--line{
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }
          }
        }
      }
      @media (min-width: $content-width-navigation){
        display: none;
      }
    }
  }
}